import { Button, Radio, Icon, Input, Loading, Dropdown, Typography, notify } from 'front-commons/ds';
import IconRotator from 'front-commons/ds/components/Icon/Rotator';
import { useDebounce, useOverlay } from 'front-commons/hooks';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { searchPosData, getUserSimulatedPosSuggestions } from 'services/pos';
import type { FindPharmaciesResponse, SimulatedPosSuggestionsResponse } from 'services/pos/interfaces';
import useCustomer from 'stores/customer';
import usePos from 'stores/pos';
import CurrentPharmacy from './CurrentPharmacy';
import { PharmacyChangeProps } from './interfaces';
import { InputWrapper, LoadingBox, PharmaciesList, PharmaciesListWrapper, TriggerContainer } from './styles';

export default function HeaderPharmacySelector() {
	const [expanded, setExpanded] = useState(false);
	const [searchValue, setSearchValue] = useState('');
	const { simulatedViewType } = useCustomer();
	const [loadingPharmacies, setLoadingPharmacies] = useState(false);
	const [selectedPos, setSelectedPos] = useState<FindPharmaciesResponse>();
	const [pharmacies, setPharmacies] = useState<FindPharmaciesResponse[]>([]);
	const [pharmacySuggestionState, setPharmacySuggestionState] = useState<'loading' | 'error' | false>(false);
	const { OverlayComponent } = useOverlay({ defaultShowOverlay: expanded, topGap: 64 });

	const { posStore, handleSelectPos, handleSetMyPos } = usePos();
	const debouncedCnpjValue = useDebounce(searchValue, 500, 7);

	const pharmacy = posStore.selectedPos;
	const isSimulatedUser = !!simulatedViewType();

	const mountPosList = () => {
		const posList = pharmacies
			.filter(
				({ cnpj, tradeName }) =>
					tradeName.toLowerCase().includes(searchValue?.toLowerCase()) || cnpj.includes(CNPJ(searchValue).unmask!),
			)
			.slice(0, 4)
			.map(({ cnpj, tradeName, pointOfSaleId }) => ({
				label: `${CNPJ(cnpj).mask} ${tradeName}`,
				value: pointOfSaleId,
			}));

		return posList;
	};

	const handleListPharmacies = useCallback(async () => {
		try {
			setLoadingPharmacies(true);
			const data = await searchPosData();

			setSelectedPos(data.find((pos) => pos.pointOfSaleId === pharmacy?.id));

			setPharmacies(data);
		} finally {
			setLoadingPharmacies(false);
		}
	}, []);

	const setPosInStorage = (data: (SimulatedPosSuggestionsResponse & { isSelected?: boolean })[]) => {
		const posMapped = data.map((pos) => {
			return {
				...pos,
				role: 'OWNER' as any,
				status: 'READY' as any,
			};
		});

		handleSetMyPos(posMapped);
	};

	const handlePharmacyClick = async ({ currentTarget: { value: pointOfSaleId } }: PharmacyChangeProps) => {
		setSearchValue('');
		setExpanded(false);

		const selectedPosFinded = pharmacies.find((pos) => pos.pointOfSaleId === pointOfSaleId) || ({} as any);

		if (isSimulatedUser) {
			setPosInStorage([{ isSelected: true, ...selectedPosFinded }]);

			// To make sure that storage has been set
			await Promise.fake({ delay: 1000 });
		}

		const missingDistributor = await handleSelectPos(pointOfSaleId);

		if (missingDistributor) return;

		const selectData = selectedPosFinded as FindPharmaciesResponse;

		setSelectedPos({
			cnpj: selectData.cnpj,
			tradeName: selectData.tradeName,
			corporateName: selectData.corporateName,
			pointOfSaleAddress: selectData.pointOfSaleAddress,
			pointOfSaleId: selectData.pointOfSaleId,
		});

		// navigate(0);
	};

	const handleSearchPdvByCnpj = useCallback(
		async (isSubmitted = false) => {
			const unMaskedCnpj = CNPJ(searchValue).unmask || '';

			if (unMaskedCnpj.length < 8) {
				if (isSubmitted) {
					notify.info({ description: 'Por favor, informe no mínimo 8 dígitos do CNPJ.' });
				}

				return null;
			}

			try {
				setPharmacySuggestionState('loading');
				const response = await getUserSimulatedPosSuggestions(unMaskedCnpj);
				setPharmacies(response as any);
				setPosInStorage(response);
				setPharmacySuggestionState(false);
			} catch {
				setPharmacySuggestionState('error');
			}
			return null;
		},
		[debouncedCnpjValue],
	);

	useEffect(() => {
		handleListPharmacies();
	}, []);

	useEffect(() => {
		if (isSimulatedUser) {
			handleSearchPdvByCnpj();
		}
	}, [handleSearchPdvByCnpj]);

	if (!loadingPharmacies && !posStore.loading && !selectedPos) return null;

	return (
		<>
			<Dropdown
				align="start"
				onOpenChange={() => setExpanded((prevState) => !prevState)}
				expanded={expanded}
				useExpanded
				offset={4}
				trigger-data-testid="pharmacy-selector-trigger"
				radiusFullDesktop
				triggerContent={
					<TriggerContainer data-testid="pharmacy-selector-trigger-content" expanded={expanded}>
						<CurrentPharmacy selectedPos={selectedPos as any} loading={loadingPharmacies || !!posStore.loading} />
						<IconRotator rotate={expanded} name="expand_more" color="--text-invert" size="16px" />
					</TriggerContainer>
				}
				disabled={loadingPharmacies}
			>
				<PharmaciesListWrapper>
					{(pharmacies.length > 1 || isSimulatedUser) && (
						<>
							<InputWrapper>
								<Input
									type="cnpj"
									name="pharmacy"
									onChange={(el) => {
										el.stopPropagation();
										setPharmacySuggestionState(false);
										setSearchValue(el.target.value);
									}}
									onKeyDown={(e) => {
										if (e.key === 'Escape') e.currentTarget.blur();

										if (e.key === 'Enter') {
											handleSearchPdvByCnpj(true);
										}
									}}
									value={searchValue}
									placeholder="Buscar farmácia"
								/>
								<Button onClick={() => handleSearchPdvByCnpj(true)} variant="none">
									<Icon name="search" size="24px" padding="0 0 0 4px" />
								</Button>
							</InputWrapper>
							<PharmaciesList
								width="100%"
								padding={{ small: '0 16px', medium: '0' }}
								direction="column"
								gap={{ small: '48px', medium: '24px' }}
							>
								<Radio
									loading={loadingPharmacies || pharmacySuggestionState === 'loading'}
									data={mountPosList()}
									onChange={handlePharmacyClick}
									name="pharmacySelector"
									outerGap={{ small: '32px', medium: '24px' }}
									innerGap={{ small: '16px', medium: '8px' }}
									direction="column"
									value={pharmacies.find((value) => value.pointOfSaleId === pharmacy?.id)?.pointOfSaleId || ''}
									variant={{ small: '16px', medium: '24px' }}
								/>
								{pharmacies.length > 4 && (
									<Link to={isSimulatedUser ? `/simular-farmacia?s=${CNPJ(searchValue).unmask}` : '/minhas-farmacias'}>
										<Typography
											style={{ textDecoration: 'underline' }}
											color="--semantic-info-text"
											variant="ParagraphSmall/Semibold"
										>
											Ver mais
										</Typography>
									</Link>
								)}
							</PharmaciesList>
						</>
					)}

					{pharmacies.length === 1 && !isSimulatedUser && (
						<Typography>Não há outras farmácias para escolher.</Typography>
					)}

					{isSimulatedUser && !pharmacies.length && pharmacySuggestionState === 'error' && (
						<Typography color="--semantic-error-text">CNPJ não encontrado.</Typography>
					)}
				</PharmaciesListWrapper>

				{!!posStore.loading && (
					<LoadingBox>
						<Loading />
					</LoadingBox>
				)}
			</Dropdown>
			<OverlayComponent />
		</>
	);
}
