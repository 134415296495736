import { Button, Flex, Icon, notify, Typography } from 'front-commons/ds';
import React from 'react';
import { renderToString } from 'react-dom/server';
import { matchPath, Location, NavigateFunction } from 'react-router-dom';
import features from 'config/featureFlags';
import { onEnvironment } from 'shared/environment';
import { mainCustomerPage } from 'stores/customer/helpers';
import { CustomerReducer } from 'stores/customer/interfaces';
import { getStore } from 'stores/helpers';
import {
	ContextsAvailable,
	DynamicPageImport,
	GeneratePageParams,
	GetStartedFromStateParams,
	PageGlobalProps,
	PageSchema,
} from './interfaces';
import {
	AlignmentTypes,
	FooterButtonActionsTypes,
	FooterButtonTypes,
	GetModalDataResponse,
} from 'services/modal/interfaces';
import { IconProps } from 'front-commons/ds/components/Icon/interfaces';

export const RemoveHTMlTags = (html: React.ReactNode | string, separator = '') => {
	if (!html) return '';
	return renderToString(html as any).replace(/<[^>]*>/g, separator);
};

export const providerHandler = ({ page: Page, provider: Provider, ...rest }: any) => {
	if (Provider)
		return (
			<Provider>
				<Page {...rest} />
			</Provider>
		);

	return <Page {...rest} />;
};

export const comparePaths = (patternPath: string, atualPath: string) => {
	const match = matchPath(patternPath, atualPath);

	return Boolean(match);
};

export const needRedirectByContext = (
	needContexts: PageGlobalProps['needContexts'],
	{ pharmacyContext, customerContext }: ContextsAvailable,
) => {
	const { token } = getStore().customerReducer;

	if (!token) {
		return '/login';
	}

	if (needContexts?.includes('pharmacy') && !pharmacyContext?.id) {
		if (customerContext?.id) {
			notify.negative({ description: 'Por favor, selecione uma farmácia para prosseguir!' });
		}

		return mainCustomerPage();
	}

	return false;
};

export const pageGenerator = (pagesContext: PageSchema) => {
	const pages: GeneratePageParams[] = [];
	Object.values(pagesContext).forEach((item) => {
		pages.push(...item);
	});

	const importPages = import.meta.glob('pages/**') as DynamicPageImport;

	if (onEnvironment('development')) {
		pages.push({
			pageDirPath: '/Sandbox',
			title: 'Debug page',
			pathname: '/test',
			headerType: 'dynamic',
		});

		pages.push({
			pageDirPath: '/Playground',
			title: 'Playground page',
			pathname: '/playground',
			headerType: 'hide',
			showFooter: false,
		});
	}

	return Object.values(pages).map(({ pageDirPath, ...params }) => ({
		page: React.lazy(importPages[`/src/pages${pageDirPath}/index.tsx`]),
		...params,
	}));
};

export const getStartedFromState = ({ pathnameRedirecting, location, hasToken }: GetStartedFromStateParams) => {
	const blacklist = ['/', '/minhas-farmacias', '/minhas-farmacias/acessos'];

	const isLoginRedirect = pathnameRedirecting === '/login';
	const userForceLogout = hasToken && !isLoginRedirect;

	if (userForceLogout || blacklist.includes(location.pathname)) {
		return null;
	}

	return { startedFrom: `${location.pathname}${location.search}` };
};

export const canAccessThisPage = (
	customerProvider: CustomerReducer['provider'],
	location: Location,
	customerToken: CustomerReducer['token'],
) => {
	if (customerProvider === null) {
		const queryParams: Record<string, string> = location.search
			.replace('?', '')
			.split('&')
			.reduce((acc, cur) => {
				const [key, value] = cur.split('=');
				return { ...acc, [key]: value };
			}, {});

		if (!queryParams.token) return '/login';

		return false;
	}

	if (['PARCEIRO_HYPERA', 'ZECA_COPILOTO'].includes(customerProvider) && customerToken) return false;

	return '/login?logout=catp';
};

export const handleWatchCustomerPhone = (
	phoneNumber: CustomerReducer['data']['phoneNumber'],
	phoneNumberConfirmed: CustomerReducer['data']['phoneNumberConfirmed'],
	customerId: CustomerReducer['data']['id'],
	pathname: string,
	protectType: PageGlobalProps['protectType'],
	navigate: NavigateFunction,
) => {
	if (!features.MANDATORY_CUSTOMER_PHONE_REGISTER) return;

	const hasPhoneANDIsConfirmedORNoIdYet = (phoneNumber && phoneNumberConfirmed) || !customerId;
	if (hasPhoneANDIsConfirmedORNoIdYet) return;

	const phoneRegisterFlux = ['/cadastro/celular/validacao', '/cadastro/celular'];
	if (['not_logged', 'public'].includes(protectType || '') || phoneRegisterFlux.includes(pathname)) return;

	navigate('/cadastro/celular', { replace: true });
};

export const headScripts = () => (
	<>
		<script async src={import.meta.env.VITE_APP_INSIDER_ID || '//hyperab2bbr.api.useinsider.com/ins.js?id=10009711'} />
		<script type="text/javascript" src="//cdn-4.convertexperiments.com/js/10046060-100411793.js" />

		<script async src="/gtmscript.js" nonce="googletm" />
		<script async src="/vwoscript.js" nonce="visualwp" />
		<script async src="/clarityscript.js" nonce="clarityms" />
	</>
);

export const formatLink = (link?: string) => {
	if (!link) return '';
	if (link.startsWith('www.')) {
		return `https://${link}`;
	}
	return link;
};

export const getActionToOnclick = (
	action?: FooterButtonActionsTypes,
	handleCloseDialog?: () => void,
	link?: string,
) => {
	switch (action) {
		case 'OPEN_NEW_TAB':
			return () => window.open(formatLink(link), '_blank');

		case 'OPEN_NEW_WINDOW':
			return () => {
				const formattedLink = formatLink(link);
				window.open(formattedLink, '_blank', 'width=800,height=600');
			};

		case 'CLOSE_MODAL':
			return () => handleCloseDialog?.();

		default:
			return undefined;
	}
};

export const getModalHeaderIcon = (iconName?: string) => {
	switch (iconName) {
		case 'warning':
		case 'error':
		case 'info':
			return { name: iconName, color: `--semantic-${iconName}-text` };

		case 'check':
			return { name: iconName, color: '--semantic-success-text' };

		default:
			return undefined;
	}
};

export const convertTypeButton = (type: FooterButtonTypes) => {
	switch (type) {
		case 'PRIMARY':
			return 'primary';
		case 'SECONDARY':
			return 'secondary';
		case 'TERTIARY':
			return 'text';
		default:
			return 'primary';
	}
};

export const convertAlignItem = (alignment: AlignmentTypes) => {
	switch (alignment) {
		case 'CENTER':
			return 'center';
		case 'LEFT':
			return 'start';
		case 'RIGHT':
			return 'end';
		default:
			return 'start';
	}
};

export const getDialogData = (
	data: GetModalDataResponse['content'],
	isDesktop: boolean,
	handleCloseDialog: () => void,
) => {
	return {
		hideHeader: !data?.header,
		hideFooter: !data?.footer,
		showCloseButtonGeneral: data?.showCloseButton,
		heading: {
			title: data?.header?.text,
			iconOptions: getModalHeaderIcon(data?.header?.icon),
			showCloseButton: data?.showCloseButton,
		},
		content: {
			children: (
				<Flex direction="column" gap="16px" width="100%">
					{data?.contentItems
						?.sort((a, b) => a.order - b.order)
						.map((item, index) => {
							switch (item.type) {
								case 'IMAGE': {
									const image = `${import.meta.env.VITE_STATIC_STORAGE_BASE + item.imageSiteUrl}`;
									const imageMobile = `${import.meta.env.VITE_STATIC_STORAGE_BASE + item.imageMobileUrl}`;
									return (
										<div key={index}>
											<img
												src={isDesktop ? image : imageMobile}
												alt=""
												style={{ width: '100%', height: '200px', maxWidth: '560px', objectFit: 'cover' }}
											/>
										</div>
									);
								}

								case 'TITLE':
									return (
										<Typography
											variant="Headline/H2 Semibold"
											key={index}
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '8px',
												justifyContent: convertAlignItem(item.alignment),
											}}
										>
											{item.icon && <Icon name={item.icon as IconProps['name']} size="24px" />}
											{item.text}
										</Typography>
									);
								case 'TEXT':
									return (
										<Typography
											variant="Paragraph/Regular"
											key={index}
											align={convertAlignItem(item.alignment)}
											dangerouslySetInnerHTML={{ __html: item.text || "" }}
										/>
									);
								case 'GROUP_BUTTON':
									return (
										<div key={index}>
											{item.buttons?.map((button, buttonIndex) => (
												<Button variant={convertTypeButton(button.buttonType)} key={buttonIndex} type="button">
													{button.label}
												</Button>
											))}
										</div>
									);
								default:
									return null;
							}
						})}
				</Flex>
			),
		},
		footer: {
			primaryButton: {
				...data?.footer?.buttons?.find((button) => button.buttonType === 'PRIMARY'),
				onClick: getActionToOnclick(
					data?.footer?.buttons?.find((button) => button.buttonType === 'PRIMARY')?.action,
					handleCloseDialog,
					data?.footer?.buttons?.find((button) => button.buttonType === 'PRIMARY')?.link,
				),
			},
			secondaryButton: {
				...data?.footer?.buttons?.find((button) => button.buttonType === 'SECONDARY'),
				onClick: getActionToOnclick(
					data?.footer?.buttons?.find((button) => button.buttonType === 'SECONDARY')?.action,
					handleCloseDialog,
					data?.footer?.buttons?.find((button) => button.buttonType === 'SECONDARY')?.link,
				),
			},
			tertiaryButton: {
				...data?.footer?.buttons?.find((button) => button.buttonType === 'TERTIARY'),
				onClick: getActionToOnclick(
					data?.footer?.buttons?.find((button) => button.buttonType === 'TERTIARY')?.action,
					handleCloseDialog,
					data?.footer?.buttons?.find((button) => button.buttonType === 'TERTIARY')?.link,
				),
			},
		},
	};
};
