import { Flex, getThemeSpace, getThemeMedia } from 'front-commons/ds';
import styled, { css } from 'styled-components';

export const LoadingBox = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	background-color: var(--neutral-black-15);
	border-radius: 8px;

	cursor: not-allowed;
`;

export const TriggerContainer = styled.div<{ expanded: boolean }>`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
	gap: ${getThemeSpace('8')};
	cursor: pointer;

	& div:nth-child(2) {
		svg {
			min-width: 16px;
		}
	}

	${({ expanded }) =>
		expanded &&
		css`
			position: relative;
			z-index: 403;
		`}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			padding-right: ${getThemeSpace('16')};
			border-radius: 4px;
			transition: background 300ms ease;
			margin: 0;
			height: 48px;

			& > svg {
				display: initial;
			}

			&:hover {
				background-color: var(--surface-accent-dark);
			}

			${({ expanded }) =>
				expanded &&
				css`
					background-color: var(--surface-accent-dark);
				`}
		}
	}
`;

export const PharmaciesListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-self: flex-start;
	gap: ${getThemeSpace('8')};

	padding: ${getThemeSpace('8')} 0;

	width: 100%;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			padding: ${getThemeSpace('16')} ${getThemeSpace('24')};
			gap: 0;
			border-radius: 4px;

			width: 360px;
		}
	}
`;

export const PharmaciesList = styled(Flex)`
	margin-top: ${getThemeSpace('16')};
	overflow: hidden;

	label {
		overflow: hidden;

		p {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			margin-top: ${getThemeSpace('24')};
		}
	}
`;

export const InputWrapper = styled.div`
	display: flex;
	align-items: center;
	background-color: var(--surface-white);
	padding: ${getThemeSpace('8')} ${getThemeSpace('16')};
	width: 100%;

	& > div:first-child {
		margin: 0;
		width: 100%;

		input {
			min-height: 40px;
			width: 100%;
		}
	}

	button {
		position: absolute;
		right: ${getThemeSpace('24')};
		border-radius: 0;
		border-left: 2px solid var(--border-primary);
		padding-left: ${getThemeSpace('8')};
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			background-color: transparent;
			padding: 0;
			& > div :first-child {
				input {
					min-height: 40px;
					width: 100%;
				}
			}

			button {
				position: absolute;
				right: ${getThemeSpace('32')};
			}
		}
	}
`;

export const CurrentPharmacyContainer = styled.div`
	display: flex;
	overflow: hidden;
	align-items: center;

	padding: 0;

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			padding-left: ${getThemeSpace('16')};
			width: 100%;
		}
	}
`;

export const PharmacySelectedLabel = styled.div`
	display: flex;

	p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
		max-width: 100px;
		text-align: left;
	}

	@media screen {
		@media (min-width: ${getThemeMedia('medium')}) {
			margin-left: ${getThemeSpace('4')};

			p {
				max-width: 140px;
			}
		}
	}
`;
