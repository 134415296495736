import { Flex, Icon, Skeleton, Typography } from 'front-commons/ds';
import { CurrentPharmacyProps } from './interfaces';
import { CurrentPharmacyContainer, PharmacySelectedLabel } from './styles';

export default function CurrentPharmacy({ selectedPos, loading }: CurrentPharmacyProps) {
	return (
		<CurrentPharmacyContainer>
			<Icon name="store" color="--text-invert" size={{ small: '24px', medium: '32px' }} />

			<Flex gap={{ small: '4px', medium: '0px' }} direction={{ medium: 'column' }} width={{ medium: '180px' }}>
				<Flex maxWidth="114px" width="100%" gap="0px" margin={{ small: '0 0 0 4px' }}>
					<Typography color="--text-invert" variant={{ small: 'ParagraphSmall/Regular', medium: 'Caption/Regular' }}>
						{loading ? <Skeleton height="14px" width="130px" margin="0 0 8px 0" /> : CNPJ(selectedPos.cnpj).mask}
					</Typography>
				</Flex>

				<Flex as={Typography} display={{ medium: 'none' }} color="--text-invert" variant="ParagraphSmall/Regular">
					|
				</Flex>

				<PharmacySelectedLabel>
					<Typography color="--text-invert" variant={{ small: 'ParagraphSmall/Regular', medium: 'Paragraph/Semibold' }}>
						{loading ? <Skeleton height="14px" width="130px" /> : selectedPos.tradeName}
					</Typography>
				</PharmacySelectedLabel>
			</Flex>
		</CurrentPharmacyContainer>
	);
}
